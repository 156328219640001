@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    margin: 0 0 1rem 0;
  }
  tr:nth-child(odd) {
    background: #ccc;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .farmerTable tr td:nth-of-type(1):before {
    content: 'Othername: ';
  }
  .farmerTable tr td:nth-of-type(2):before {
    content: 'Surname: ';
  }
  .farmerTable tr td:nth-of-type(3):before {
    content: 'Phone: ';
  }
  .farmerTable tr td:nth-of-type(4):before {
    content: 'Email: ';
  }
  .farmerTable tr td:nth-of-type(5):before {
    content: 'Account No: ';
  }
  .farmerTable tr td:nth-of-type(6):before {
    content: 'Sex: ';
  }
  .farmerTable tr td:nth-of-type(7):before {
    content: 'BVN: ';
  }
  .farmerTable tr td:nth-of-type(8):before {
    content: 'Status: ';
  }
  .farmerTable tr td:nth-of-type(9):before {
    content: '';
  }
}
