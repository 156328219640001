.card-description {
  display: flex;
  justify-content: flex-start;
}

/* Style for the date input */

input[type='date'] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

/* Optional: Style for the date picker popup */

input[type='date']::-webkit-calendar-picker-indicator {
  color: #999;
  opacity: 0.7;
  cursor: pointer;
}

/* Optional: Adjust appearance for different browsers */

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-clear-button {
  display: none;
}

input[type='date']::-webkit-datetime-edit {
  padding: 5px;
  color: #333;
}

input[type='date']::-webkit-datetime-edit-fields-wrapper {
  display: flex;
  justify-content: space-between;
}

input[type='date']::-webkit-datetime-edit-text {
  flex: 1;
}

input[type='date']::-webkit-datetime-edit-minute,
input[type='date']::-webkit-datetime-edit-hour {
  flex: 0.5;
}
