table {
  all: initial;
}

@media only screen and (max-width: 760px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: initial;
  }
}
