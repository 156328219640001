/* my-editor-style.css */

/* Set the background color of the editor container */
.ql-container {
  background-color: white; /* Change this to the desired background color */
}

/* Optionally, you can also change the text color inside the editor */
.ql-editor {
  color: black; /* Change this to the desired text color */
}
