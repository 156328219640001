.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #070c0d;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  body {
    background-color: #fff;
  }
  .ant-picker-panels {
    flex-direction: row;
    transform: translateY(0);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 10px;
    font-variant: tabular-nums;
    line-height: 1;
    list-style: none;
    font-feature-settings: 'tnum';
  }
  .ant-picker-panel-layout {
    max-width: 300px;
    overflow-x: scroll;
  }
  .ant-picker-panel {
    max-width: 300px;
    overflow-x: scroll;
  }
}

.login-form-wrapper {
  background-color: black;
}

.ant-layout-sider-children,
.ant-menu {
  background-color: black;
}

.ant-btn-primary {
  background-color: #090909;
  color: rgb(253, 246, 246);
}

.ant-btn-primary:hover {
  background-color: #343333;
  color: rgb(253, 246, 246);
}

.ant-menu-item-selected:hover {
  background-color: #4e4c4c;
  color: rgb(253, 246, 246);
}

.adjust-responsive {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .ant-table {
    font-size: 12px;
    /* Reduce font size for smaller screens */
  }
  .ant-table-column-title {
    font-size: 12px;
    /* Reduce column header font size */
  }
  .ant-table-thead th {
    text-align: center;
    /* Center-align column headers */
  }
  .ant-table-tbody td {
    text-align: center;
    /* Center-align table cell content */
  }
  .hide-on-mobile {
    display: none;
    /* Hide specific columns on smaller screens */
  }
  .adjust-responsive {
    width: 95%;
  }
}
