@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background: #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  .monitorTable tr td:nth-of-type(1):before {
    content: 'Farmer Name: ';
  }
  .monitorTable tr td:nth-of-type(2):before {
    content: 'Account No: ';
  }
  .monitorTable tr td:nth-of-type(3):before {
    content: 'Activity: ';
  }
  .monitorTable tr td:nth-of-type(4):before {
    content: 'Crop Type: ';
  }
  .monitorTable tr td:nth-of-type(5):before {
    content: 'Grade ID: ';
  }
  .monitorTable tr td:nth-of-type(6):before {
    content: 'Responsiveness: ';
  }
  .monitorTable tr td:nth-of-type(7):before {
    content: 'Activity Cycle: ';
  }
  .monitorTable tr td:nth-of-type(8):before {
    content: 'Approach: ';
  }
  .monitorTable tr td:nth-of-type(9):before {
    content: '';
  }
}
